import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "../../components/helper/Button/Button";
import api from "../../helper/api";
import { CONTACT, EMAIL } from "../../helper/endpoints";
import validationSchema from "./contactSchema";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoLogoWhatsapp } from "react-icons/io5";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { startsWith } from "lodash.startswith";
const ContactUs = ({
  sucessSnackBarOpen,
  setSucessSnackBarOpen,
  warningSnackBarOpen,
  setWarningSnackBarOpen,
}) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
    country_code: "",
  };

  const [submitCount, setSubmitCount] = useState(0);
  const [phoneN, setPhoneN] = useState("");
  const [contrecode, setContrecode] = useState("");

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      try {
        api.postWithToken(`${process.env.REACT_APP_API + CONTACT}`, values);
        api
          .postWithToken(`${process.env.REACT_APP_API + EMAIL}`, values)
          .then(
            (res) => resetForm(),
            setSucessSnackBarOpen(!sucessSnackBarOpen),
            setSubmitCount(0)
          );
      } catch {
        setWarningSnackBarOpen(!warningSnackBarOpen);
      }
    },
  });

  const handleOnChange = (value, country, event, formattedValue) => {
    let splitN = value.split(country?.dialCode);
    let contrecode = "+" + country?.dialCode;
    let PhoneN = splitN?.[1] || "";
    setPhoneN(PhoneN);
    setContrecode(contrecode);
    setFieldValue("phone", PhoneN);
    setFieldValue("country_code", contrecode);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        id="first_name"
        className=" mt-5 bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
        placeholder="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
      />
      <p className="ml-2 text-xs text-red-500">
        {submitCount === 1 && errors.name}
      </p>
      <div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2">
        <div>
          <input
            type="email"
            id="first_name"
            className="bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <p className="ml-2 text-xs text-red-500">
            {submitCount === 1 && errors.email}
          </p>
        </div>
        <div className="relative">
          <PhoneInput
            country={"in"} // Default country
            value={`${values.country_code}${values.phone}`}
            onChange={(value, country, event, formattedValue) => {
              handleOnChange(value, country, event, formattedValue);
            }}
            // onChange={(value) => setFieldValue("phone", value)}
            className={`outline-none  w-full ${
              submitCount === 1 ? "" : "h-full"
            }  !rounded-lg `}
            inputClass="!bg-gray-50 !border-blue text-gray-900 !text-sm !rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 !h-full !w-full"
            buttonClass="!border-blue"
            enableSearch
            // countryCodeEditable={false}
            autoFormat
          />
          <p className="ml-2 text-xs text-red-500">
            {submitCount === 1 && errors.phone}
          </p>
          <div
            className={`absolute  ${
              submitCount === 1 ? "h-[68%]" : "h-full"
            } w-[50px] z-20 top-0 right-0 rounded-r-lg flex justify-center items-center`}
          >
            <IoLogoWhatsapp size={28} className="fill-green-600" />
          </div>
        </div>
      </div>
      <textarea
        rows={5}
        type="text"
        id="first_name"
        className="mt-5 bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
        placeholder="Message"
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <p className="ml-2 text-xs text-red-500">
        {submitCount === 1 && errors.message}
      </p>

      <Button
        style="bg-blue rounded-[8px] w-full py-2 text-white mt-5"
        text="Submit"
        type="submit"
        onClick={() => setSubmitCount(1)}
      />
    </form>
  );
};

export default ContactUs;
