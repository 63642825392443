import { useState } from "react";
import Heading from "../../components/helper/Heading";
import RouteAnimation from "../../components/helper/RouteAnimation";
import ContactUs from "../../components/ContactUs/ContactUs";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import SEO from "../../components/helper/SEO/SEO";

const Contact = () => {
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);

  return (
    <>
      <SEO
        title="Contact Us - SmartTechnica"
        description="Get in touch with SmartTechnica for all your mobile and web development needs. Fill out the form, and our team will respond promptly to assist you."
        keywords="contact SmartTechnica, mobile development inquiry, web development inquiry, IT services contact, software development, app development contact, SmartTechnica customer service, tech solutions contact, business inquiries"
        image="/assets/images/og/og_contact.png"
        url="https://smarttechnica.com/contact-us"
      />

      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Thank you for contacting us !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />
      <RouteAnimation>
        <div className="relative mb-10 overflow-hidden">
          <img
            src="/assets/images/servicesNew/firstArrow.png"
            className="absolute top-96"
            alt=""
          />
          <div className="mt-10 max-w-[95%] md:max-w-[85%] mx-auto">
            <Heading
              styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
              image="/assets/images/Contact/Contact.svg"
              text="Let's talk business!"
            />
            <div className="mt-16 grid gap-5 grid-cols-1 lg:grid-cols-2 items-center">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="2xl:max-w-[80%] xl:max-w-[90%] mx-auto"
              >
                <img
                  className="mx-auto"
                  src="/assets/images/Contact/contactMain.svg"
                  alt="contact"
                />
              </div>
              <div data-aos="fade-left" data-aos-duration="1500">
                <div>
                  <p className="text-blue text-[18px] md:text-[20px]">
                    One stop solution for mobile and web development related
                    inquiries. fill up the form below and you will hear from us
                    shortly.
                  </p>
                  <ContactUs
                    sucessSnackBarOpen={sucessSnackBarOpen}
                    setSucessSnackBarOpen={setSucessSnackBarOpen}
                    warningSnackBarOpen={warningSnackBarOpen}
                    setWarningSnackBarOpen={setWarningSnackBarOpen}
                  />
                </div>
              </div>
            </div>
            <img
              className="-z-[999] absolute top-[10%] left-[40%]"
              src="/assets/images/servicesNew/BlueCircle.svg"
              alt=""
            />
            <img
              className="-z-[999] absolute top-[20%] right-[50%]"
              src="/assets/images/servicesNew/YellowCircle.svg"
              alt=""
            />
            <div className="mt-10 gap-5 lg:gap-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="rounded-3xl">
                <iframe
                  title="map"
                  className="mx-auto"
                  width="99%"
                  height="400"
                  frameBorder="0"
                  scrolling="no"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=smart%20technica+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
              <div className="flex">
                <div className="my-auto">
                  <div className="ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white flex rounded-2xl items-center p-[10px] fold:p-5">
                    <img
                      className="mr-4 w-[50px] esm:with-[44px]"
                      src="/assets/images/Contact/footeremail.svg"
                      alt=""
                    />
                    <p className="text-[18px] md:text-[22px]">
                      <a href="mailto:info@Smarttechnica.com">
                        Info@Smarttechnica.Com
                      </a>
                    </p>
                  </div>
                  <div className="ring-2 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white my-5 flex rounded-2xl items-center p-[10px] fold:p-5">
                    <img
                      className="mr-4 w-[50px] esm:with-[44px]"
                      src="/assets/images/Contact/footerphone.svg"
                      alt=""
                    />
                    <p className="text-[18px] md:text-[22px]">
                      <a href="tel:+918866294757">+91 88 66 294757</a>
                    </p>
                  </div>
                  <div className="ring-2 ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white flex rounded-2xl items-center p-[10px] fold:p-5">
                    <img
                      className="mr-4 w-[50px] esm:with-[44px]"
                      src="/assets/images/Contact/footerlocation.svg"
                      alt=""
                    />
                    <p className="text-[18px] md:text-[22px]">
                      <a
                        href="https://goo.gl/maps/jxf7oaoP2KWoycxw9"
                        target="_New"
                      >
                        301, Green Plaza, Golden Howk,Golden City Rd, Mota
                        Varachha, Surat, Gujarat 3941071
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RouteAnimation>
    </>
  );
};

export default Contact;
