import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "../helper/Button/Button";
import ScrollContainer from "react-indiana-drag-scroll";
import Heading from "../helper/Heading";
import { SERVICES, Requirment } from "../../helper/constants";
import api from "../../helper/api";
import { QUOTE } from "../../helper/endpoints";
import validationSchema from "./quoteSchema";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  mobile_number: "",
  budget: "",
  how_soon_you_want_to_start: "",
  support_maintenance: false,
  new_project: false,
  existing_project: false,
  hire_desicated_team: false,
  requirement: [],
  service: [],
  project_description: "",
  files: "",
};

const Quote = ({
  sucessSnackBarOpen,
  setSucessSnackBarOpen,
  warningSnackBarOpen,
  setWarningSnackBarOpen,
}) => {
  const [submitCount, setSubmitCount] = useState(0);
  const [show, setShow] = useState(true);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (value) => {
      if (value.requirement.length !== 0 && value.service.length !== 0) {
        try {
          const formData = new FormData();
          formData.append("first_name", value.first_name);
          formData.append("last_name", value.last_name);
          formData.append("email", value.email);
          formData.append("mobile_number", value.mobile_number);
          formData.append("budget", value.budget);
          formData.append("when_to_start", value.how_soon_you_want_to_start);
          formData.append("project_description", value.project_description);
          formData.append("service", JSON.stringify(value.service));
          formData.append("requirement", JSON.stringify(value.requirement));

          for (let i = 0; i < value.files.length; i++) {
            formData.append("files", value.files[i], value.files[i].name);
          }

          api
            .post(`${process.env.REACT_APP_API + QUOTE}`, formData)
            .then((res) => {
              setSubmitCount(0);
              resetForm();
              document
                .getElementsByName("ids[]")
                .forEach((e) => (e.checked = false));
              document
                .getElementsByName("budget")
                .forEach((e) => (e.checked = false));
              document
                .querySelectorAll("#service-options")
                .forEach(
                  (e) =>
                    (e.className = "p-3 bg-white border border-blue rounded-xl")
                );
              setSucessSnackBarOpen(!sucessSnackBarOpen);
            })
            .catch((error) => {
              setWarningSnackBarOpen(!warningSnackBarOpen);
            });
        } catch {
          setWarningSnackBarOpen(!warningSnackBarOpen);
        }
      }
    },
  });

  const handelcheckbox = (e) => {
    var arrayids = [];
    document.getElementsByName("ids[]").forEach((e) => {
      if (e.checked) {
        arrayids.push(e.value);
      }
    });
    values.requirement = arrayids;
    arrayids.length === 0 ? setShow(true) : setShow(false);
  };

  const handleCheckboxChange = (e) => {
    var arrayids = [];
    e.target.className ===
    "px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]"
      ? (e.target.className =
          "px-3 py-1.5 text-white border border-white rounded-xl text-[18px] bg-blue")
      : (e.target.className =
          "px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]");
    if (arrayids.includes(e.target.value)) {
      arrayids.filter((val) => val != e.target.value);
    } else {
      arrayids.push(e.target.value);
    }
    values.requirement = arrayids;
    arrayids.length === 0 ? setShow(true) : setShow(false);
  };

  const handleServices = (e) => {
    e.target.className ===
    "px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]"
      ? (e.target.className =
          "px-3 py-1.5 text-white border border-white rounded-xl text-[18px] bg-blue")
      : (e.target.className =
          "px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]");

    values.service.includes(e.target.innerHTML)
      ? setFieldValue(
          "service",
          values.service.filter((value) => value !== e.target.innerHTML)
        )
      : setFieldValue("service", [...values.service, e.target.innerHTML]);
  };

  return (
    <>
      <div
        data-aos="zoom-out"
        data-aos-duration="1500"
        className="max-w-[95%] md:max-w-[85%] mx-auto"
      >
        <Heading
          styles="absolute w-full top-6 sm:top-12 text-center mx-auto"
          image="/assets/images/Career/getfreequote.png"
          text="Request for quote"
        />
        {/* old design */}
        <div className="w-[100%] h-[100%] ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white mt-5 mx-auto relative  rounded-lg">
          <div className=" max-w-[95%] md:max-w-[95%] mx-auto text-blue">
            <form
              onSubmit={handleSubmit}
              className="overflow-hidden"
              noValidate
            >
              <div className="grid items-center grid-cols-1 lg:grid-cols-2">
                <div className="p-2 sm:p-5 space-y-2">
                  <h3 className="mb-4 text-base md:text-xl font-semibold underline underline-offset-4 decoration-blue ">
                    Personal details
                  </h3>
                  <div className="items-center">
                    <label className=" text-base esm:text-[18px]">Name*</label>
                    <div className="flex gap-3 mt-1">
                      <div>
                        <input
                          className="w-full px-2 py-1 border rounded outline-none placeholder:text-gray-600 border-blue text-base esm:text-[18px] text-black"
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        <div className="mt-1">
                          <p className="ml-2 text-xs text-red-500">
                            {submitCount === 1 && errors.first_name}
                          </p>
                        </div>
                      </div>
                      <div>
                        <input
                          className="float-right mb-1 w-full px-2 py-1 border rounded outline-none placeholder:text-gray-600 border-blue text-base esm:text-[18px] text-black"
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        <div className="">
                          <p className="ml-2 text-xs text-red-500">
                            {submitCount === 1 && errors.last_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-center">
                    <label className="text-base esm:text-[18px]">Email*</label>
                    <div className="mt-1">
                      <input
                        className="w-full px-2 py-1 border rounded outline-none placeholder:text-gray-600 border-blue text-base esm:text-[18px] text-black"
                        type="email"
                        name="email"
                        placeholder="Enter your valid email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <div className="mt-1">
                        <p className="ml-2 text-xs text-red-500">
                          {submitCount === 1 && errors.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <label className="text-base esm:text-[18px]">
                      Mobile number*
                    </label>
                    <div className="mt-1">
                      <input
                        className="w-full px-2 py-1 border rounded outline-none placeholder:text-gray-600 border-blue text-base esm:text-[18px] text-black"
                        type="number"
                        name="mobile_number"
                        placeholder="Mobile Number"
                        value={values.mobile_number}
                        onChange={handleChange}
                      />

                      <div className="mt-1">
                        <p className="ml-2 text-xs text-red-500">
                          {submitCount === 1 && errors.mobile_number}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block">
                  <img
                    className="mx-auto"
                    src="/assets/images/form/formMain.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="p-4 space-y-5">
                <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                  <h3 className="text-base md:text-xl font-semibold underline underline-offset-4 decoration-blue w-1/2">
                    Services
                  </h3>
                  <h3 className="text-xs md:text-lg font-semibold underline-offset-4 decoration-blue w-1/2 text-center">
                    Let's Build Your Project Together !
                  </h3>
                </div>
                <div className="">
                  <label
                    htmlFor="countries"
                    className="text-base esm:text-[18px] font-medium"
                  >
                    Select Service*
                  </label>
                  <div className="text-gray-600">
                    <ScrollContainer
                      vertical={false}
                      className="scroll-container"
                    >
                      <div className="flex gap-4 my-1 cursor-pointer w-fit whitespace-nowrap">
                        {SERVICES.map((value, index) => (
                          <p
                            key={index}
                            onClick={handleServices}
                            id="service-options"
                            className="px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]"
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                      <div className="-mt-4 mb-5">
                        {submitCount === 1 && values.service.length === 0 && (
                          <p className="ml-2 text-xs text-red-500">
                            Services are required!
                          </p>
                        )}
                      </div>
                    </ScrollContainer>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="budget"
                    className="text-base esm:text-[18px] font-medium"
                  >
                    Budget*
                  </label>
                  <div className="text-gray-600">
                    <ScrollContainer
                      vertical={false}
                      className="scroll-container h-[50px]"
                    >
                      <div className="flex gap-4 my-1 cursor-pointer budget w-fit whitespace-nowrap">
                        <input
                          id="fk"
                          type="radio"
                          name="budget"
                          onChange={handleChange}
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="fk"
                        >
                          $500 - $1K
                        </label>
                        <input
                          id="tk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$500 - $1K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="tk"
                        >
                          $1K - $5K
                        </label>
                        <input
                          id="ftk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$1K - $5K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="ftk"
                        >
                          $5K - $10K
                        </label>
                        <input
                          id="hk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$5K - $10K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="hk"
                        >
                          $10K - $20K
                        </label>
                        <input
                          id="tftk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$10K - $20K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="tftk"
                        >
                          $20K - $50K
                        </label>

                        <input
                          id="fkm"
                          type="radio"
                          name="budget"
                          onChange={handleChange}
                          value="$20K - $50K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="fkm"
                        >
                          $50K +
                        </label>
                      </div>
                      <div className="-mt-4">
                        <p className="ml-2 text-xs text-red-500">
                          {submitCount === 1 && errors.budget}
                        </p>
                      </div>
                    </ScrollContainer>
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  <div className="mt-5">
                    <label
                      htmlFor="start"
                      className="text-base esm:text-[18px] font-medium"
                    >
                      How soon you want to start*
                    </label>
                    <div className="mt-1">
                      <select
                        id="start"
                        className="w-[90%] px-2 py-1 text-gray-600 border border-blue rounded outline-none text-[18px]"
                        name="how_soon_you_want_to_start"
                        value={values.how_soon_you_want_to_start}
                        onChange={handleChange}
                      >
                        <option value="Today">Today</option>
                        <option value="CA">Tomorrow</option>
                        <option value="FR">In next few days</option>
                        <option value="DE">In 15 days</option>
                        <option value="FR">In next month</option>
                      </select>
                    </div>
                    <div className="mt-1">
                      <p className="ml-2 text-xs text-red-500">
                        {submitCount === 1 && errors.how_soon_you_want_to_start}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="text-base esm:text-[18px] font-medium">
                      Requirement*
                    </label>
                    <div className="mt-5 flex justify-between w-full esm:w-[90%]">
                      <div className="flex-col">
                        <div className="mb-2">
                          <input
                            className="h-3 esm:h-[18px] w-3 esm:w-[18px] mr-1 border text-gray-600 border-blue outline-none"
                            type="checkbox"
                            id="support-maintenance"
                            name="ids[]"
                            value="support maintenance"
                            onChange={(e) => handelcheckbox(e)}
                          />
                          <label
                            className=" text-gray-600 text-xs esm:text-[18px]"
                            htmlFor="support-maintenance"
                          >
                            Support-maintenance
                          </label>
                        </div>
                        <div>
                          <input
                            className="h-3 esm:h-[18px] w-3 esm:w-[18px] mr-1 border border-blue text-gray-600 outline-none"
                            type="checkbox"
                            id="existing-project"
                            name="ids[]"
                            value="existing project"
                            onChange={(e) => handelcheckbox(e)}
                          />
                          <label
                            className="text-gray-600 text-xs esm:text-[18px]"
                            htmlFor="existing-project"
                          >
                            Existing project
                          </label>
                        </div>
                        <div>
                          {submitCount === 1 && show && (
                            <p className="ml-2 text-xs text-red-500">
                              requirements are required!
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex-col">
                        <div className="mb-2">
                          <input
                            className="h-3 esm:h-[18px] w-3 esm:w-[18px] mr-1 border border-blue text-gray-600 outline-none"
                            type="checkbox"
                            id="new-project"
                            name="ids[]"
                            value="new project"
                            onChange={(e) => handelcheckbox(e)}
                          />
                          <label
                            className="text-gray-600 text-xs esm:text-[18px]"
                            htmlFor="new-project"
                          >
                            New project
                          </label>
                        </div>
                        <div>
                          <input
                            className="h-3 esm:h-[18px] w-3 esm:w-[18px] mr-1 border border-blue text-gray-600 outline-none"
                            type="checkbox"
                            id="hire-desicated-team"
                            name="ids[]"
                            value="hire desicated team"
                            onChange={(e) => handelcheckbox(e)}
                          />
                          <label
                            className=" text-gray-600 text-xs esm:text-[18px]"
                            htmlFor="hire-desicated-team"
                          >
                            Hire desiccated team
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2 sm:p-5">
                <h3 className="mb-4 text-base md:text-xl font-semibold underline underline-offset-4 decoration-blue">
                  Project description
                </h3>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <div className="mt-5">
                    <label
                      htmlFor="project-description"
                      className="text-base esm:text-[18px] font-medium"
                    >
                      Brief project description?
                    </label>
                    <div className="mt-1">
                      <textarea
                        className="w-full px-2 py-1 text-gray-600 border rounded outline-none placeholder:text-gray-600 border-blue text-[18px]"
                        id="project-description"
                        type="text"
                        name="project_description"
                        placeholder="Brief project description"
                        value={values.project_description}
                        onChange={handleChange}
                      />
                      <div>
                        <p className="ml-2 text-xs text-red-500">
                          {submitCount === 1 && errors.project_description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="hire-input"
                      className="text-base esm:text-[18px] font-medium"
                    >
                      Want to share a file?
                    </label>
                    <div className="flex items-center justify-between w-full gap-5 px-2 py-1 mt-1 bg-white border rounded align border-blue text-[18px]">
                      <input
                        id="hire-input"
                        type="file"
                        className="hidden"
                        name="file"
                        placeholder="Choose file(s)"
                        multiple
                        onChange={(event) =>
                          setFieldValue("files", event.target.files)
                        }
                      />
                      <span className="text-gray-600">Choose File(S)</span>
                      <label
                        htmlFor="hire-input"
                        className="mr-4 cursor-pointer"
                      >
                        <img
                          src="/assets/images/about us/file-upload.png"
                          alt=""
                        />
                      </label>
                    </div>
                    <div className="mt-1">
                      <p className="ml-2 text-xs text-red-500">
                        {submitCount === 1 && errors.files}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center py-10">
                <Button
                  style="bg-blue text-white rounded-lg mt-5 py-3 px-10"
                  text="Submit"
                  type="submit"
                  onClick={() => setSubmitCount(1)}
                />
              </div>
            </form>
          </div>
        </div>
        {/* new design */}
        {/* <div className="w-[100%] h-[100%] ring-2 ring-[#1A76B9] bg-[#F3FAFF] mt-5 mx-auto relative rounded-lg lg:p-10 sm:p-6 p-4">
          <form
            onSubmit={handleSubmit}
            className="overflow-hidden space-y-6"
            noValidate
          >
            <div className=" bg-white border border-[#1A76B9] rounded-2xl py-4">
              <h3 className="lg:text-[24px] sm:text-[20px] text-[18px] text-[#1A76B9] font-medium px-4">
                Personal details:
              </h3>
              <div className="bg-[#1A76B9] my-4 w-full h-[1px]" />
              <div className="px-4 grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
                <div>
                  <input
                    className="w-full px-2 py-1 text-[16px] placeholder:text-[#000] border border-[#a3c8e3] rounded-[6px] focus:outline-[#1A76B9]"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  <div className="mt-1">
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.first_name}
                    </p>
                  </div>
                </div>
                <div>
                  <input
                    className="w-full px-2 py-1 text-[16px] placeholder:text-[#000] border border-[#a3c8e3] rounded-[6px] focus:outline-[#1A76B9]"
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  <div className="">
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.last_name}
                    </p>
                  </div>
                </div>
                <div>
                  <input
                    className="w-full px-2 py-1 text-[16px] placeholder:text-[#000] border border-[#a3c8e3] rounded-[6px] focus:outline-[#1A76B9]"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <div className="">
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.email}
                    </p>
                  </div>
                </div>
                <div>
                  <input
                    className="w-full px-2 py-1 text-[16px] placeholder:text-[#000] border border-[#a3c8e3] rounded-[6px] focus:outline-[#1A76B9]"
                    type="number"
                    name="mobile_number"
                    placeholder="Mobile Number"
                    value={values.mobile_number}
                    onChange={handleChange}
                  />
                  <div className="">
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.mobile_number}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white border border-[#1A76B9] rounded-2xl py-4">
              <h3 className="lg:text-[24px] sm:text-[20px] text-[18px] text-[#1A76B9] font-medium px-4">
                Services:
              </h3>
              <div className="bg-[#1A76B9] my-4 w-full h-[1px]" />
              <div className="px-4 grid grid-cols-2 gap-4">
                <div className="col-span-2">
                  <label
                    htmlFor="countries"
                    className="esm:text-[18px] text-[16px] font-medium"
                  >
                    Select Service*
                  </label>
                  <div className="text-gray-600 mt-2">
                    <ScrollContainer
                      vertical={false}
                      className="scroll-container"
                    >
                      <div className="flex gap-4 my-1 cursor-pointer w-fit whitespace-nowrap">
                        {SERVICES.map((value, index) => (
                          <p
                            key={index}
                            onClick={handleServices}
                            id="service-options"
                            className="px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]"
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                      <div className="-mt-4 mb-5">
                        {submitCount === 1 && values.service.length === 0 && (
                          <p className="ml-2 text-xs text-red-500">
                            Services are required!
                          </p>
                        )}
                      </div>
                    </ScrollContainer>
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="countries"
                    className="esm:text-[18px] text-[16px] font-medium"
                  >
                    Budget*
                  </label>
                  <div className="text-gray-600 mt-2">
                    <ScrollContainer
                      vertical={false}
                      className="scroll-container h-[50px]"
                    >
                      <div className="flex gap-4 my-1 cursor-pointer budget w-fit whitespace-nowrap">
                        <input
                          id="fk"
                          type="radio"
                          name="budget"
                          onChange={handleChange}
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="fk"
                        >
                          $500 - $1K
                        </label>
                        <input
                          id="tk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$500 - $1K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="tk"
                        >
                          $1K - $5K
                        </label>
                        <input
                          id="ftk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$1K - $5K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="ftk"
                        >
                          $5K - $10K
                        </label>
                        <input
                          id="hk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$5K - $10K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="hk"
                        >
                          $10K - $20K
                        </label>
                        <input
                          id="tftk"
                          onChange={handleChange}
                          type="radio"
                          name="budget"
                          value="$10K - $20K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="tftk"
                        >
                          $20K - $50K
                        </label>

                        <input
                          id="fkm"
                          type="radio"
                          name="budget"
                          onChange={handleChange}
                          value="$20K - $50K"
                        />
                        <label
                          id="budget-options"
                          className="px-3 py-1.5 text-[18px] bg-white border border-blue rounded-xl"
                          htmlFor="fkm"
                        >
                          $50K +
                        </label>
                      </div>
                      <div className="-mt-4">
                        <p className="ml-2 text-xs text-red-500">
                          {submitCount === 1 && errors.budget}
                        </p>
                      </div>
                    </ScrollContainer>
                  </div>
                </div>
                <div className="sm:col-span-1 col-span-2">
                  <label
                    htmlFor="start"
                    className="esm:text-[18px] text-[16px] font-medium"
                  >
                    How soon you want to start*
                  </label>
                  <div className="mt-1">
                    <select
                      id="start"
                      className="w-full px-2 py-1 text-[18px] placeholder:text-[#1A76B9] border border-[#a3c8e3] rounded focus:outline-[#1A76B9]"
                      name="how_soon_you_want_to_start"
                      value={values.how_soon_you_want_to_start}
                      onChange={handleChange}
                    >
                      <option value="Today">Today</option>
                      <option value="CA">Tomorrow</option>
                      <option value="FR">In next few days</option>
                      <option value="DE">In 15 days</option>
                      <option value="FR">In next month</option>
                    </select>
                  </div>
                  <div className="mt-1">
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.how_soon_you_want_to_start}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="countries"
                    className="esm:text-[18px] text-[16px] font-medium"
                  >
                    Requirement*
                  </label>
                  <div className="text-gray-600 mt-2">
                    <ScrollContainer
                      vertical={false}
                      className="scroll-container"
                    >
                      <div className="flex gap-4 my-1 cursor-pointer w-fit whitespace-nowrap">
                        {Requirment.map((value, index) => (
                          <p
                            key={index}
                            onClick={handleCheckboxChange}
                            id="service-options"
                            className="px-3 py-1.5 bg-white border border-blue rounded-xl text-[18px]"
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                      <div className="-mt-4 mb-5">
                        {submitCount === 1 && values.service.length === 0 && (
                          <p className="ml-2 text-xs text-red-500">
                            Services are required!
                          </p>
                        )}
                      </div>
                    </ScrollContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white border border-[#1A76B9] rounded-2xl py-4">
              <h3 className="lg:text-[24px] sm:text-[20px] text-[18px] text-[#1A76B9] font-medium px-4">
                Project Description :
              </h3>
              <div className="bg-[#1A76B9] my-4 w-full h-[1px]" />
              <div className="px-4 grid sm:grid-cols-9 grid-cols-1 gap-4">
                <div className="sm:col-span-3 col-span-1">
                  <label
                    htmlFor="hire-input"
                    className="p-[30px] bg-[#F3FAFF] block rounded-[16px] cursor-pointer"
                  >
                    <div className="h-[40px] w-[40px] border border-[#1A76B9] rounded-[16px] flex justify-center items-center bg-white mx-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12.0042 20.5L12 11.5"
                          stroke="#1A76B9"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.2591 17.1581C21.9024 16.0025 22.607 13.9151 22.0003 12C21.3935 10.0849 19.5355 9.0357 17.5266 9.03725H16.3661C15.6074 6.08065 13.1043 3.89786 10.072 3.5486C7.03959 3.19934 4.10585 4.7559 2.6949 7.46265C1.28395 10.1694 1.68797 13.4659 3.71082 15.7518"
                          stroke="#1A76B9"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.1824 13.8179L12.0004 10.636L8.81836 13.8179"
                          stroke="#1A76B9"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h4 className="text-[#1A76B9] text-center mt-2">
                      Choose File
                    </h4>
                  </label>
                  <input
                    id="hire-input"
                    type="file"
                    className="hidden"
                    name="file"
                    placeholder="Choose file(s)"
                    multiple
                    onChange={(event) =>
                      setFieldValue("files", event.target.files)
                    }
                  />
                </div>
                <div className="sm:col-span-6 col-span-1">
                  <textarea
                    className="w-full px-2 py-1 text-[18px] placeholder:text-[#000] border border-[#a3c8e3] rounded-[6px] focus:outline-[#1A76B9] h-full"
                    id="project-description"
                    type="text"
                    name="project_description"
                    placeholder="Brief project description"
                    rows={5}
                    value={values.project_description}
                    onChange={handleChange}
                  />
                  <div>
                    <p className="ml-2 text-xs text-red-500">
                      {submitCount === 1 && errors.project_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                style="bg-blue text-white rounded-lg py-3 px-10"
                text="Submit"
                type="submit"
                onClick={() => setSubmitCount(1)}
              />
            </div>
          </form>
        </div> */}
      </div>
    </>
  );
};

export default Quote;
