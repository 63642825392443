import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "../../components/helper/Carousel/Card";
import Heading from "../../components/helper/Heading";

const HomeSection2 = () => {
  const options = {
    margin: 30,
    autoplay: true,
    smartSpeed: 1000,
    // center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1350: {
        items: 3,
      },
      1815: {
        items: 4,
      },
    },
    nav: false,
    dots: false,
  };

  return (
    <div className="mt-20 max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
      <Heading
        styles="absolute w-full top-[38px] text-center mx-auto"
        image="/assets/images/servicesNew/servicLogo.svg"
        text="Our exceptional IT services"
      />
      {/* <OwlCarousel
        data-aos="fade-up"
        data-aos-duration="1500"
        className="slider-items owl-theme map-slider owl-carousel owl-loaded owl-drag"
        stageClass="owl-stage h-[500px] flex items-center"
        loop
        {...options}
      >
        <Card
          color="ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white"
          image="/assets/images/home/uiuxLogo.png"
          heading="UI/UX Design"
          text=" UI/UX design is the crowning glory of any web or mobile app. we
            deliver the best design that is user-friendly which gives users a
            prominent experience. creative ui/ux designs that impel your brand
            ahead of competitors and speak directly to your brand's value. our
            team of skilled creative designers understands the requirements of
            clients before designing ul and delivers splendid ui/ux design for
            strategic business growth."
          headingClass={"text-[#000]"}
          contentClass={"text-[#000]"}
        />
        <Card
          color="ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white"
          image="/assets/images/home/QaLogo.png"
          heading="QA services (quality assurance)"
          text="Our quality assurance intent to deliver a collaborative approach
            ranging from functionality testing, design testing, usability
            testing, and app compatibility testing that suitable our client's
            expectations. the process enables quality control and assures the
            application is tested thoroughly before being launched."
          headingClass={"text-[#000]"}
          contentClass={"text-[#000]"}
        />
        <Card
          color="ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white"
          image="/assets/images/home/devopslogo.png"
          heading="DevOps"
          text="Devops is the combination of cultural philosophies,practices, and
            tools that increases an organization's ability to deliver
            applications and services at high velocity evolving and
            improving products at a faster pace than organizations using
            traditional software development and infrastructure management
            processes."
          headingClass={"text-[#000]"}
          contentClass={"text-[#000]"}
        />
        <Card
          color="ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white"
          image="/assets/images/home/mobileLogo.png"
          heading="Mobile app development"
          text="Transforming business ideas and build a best solutions through
            creative mobile app development. we develop ios, android apps, and
            progressive web apps development, that demonstrate to you, and your
            challenges. as a result, you will secure a high-quality application
            with a great user interface and enhanced security. we are expert in
            making your apps customized including every aspect from design to
            functionality."
          headingClass={"text-[#000]"}
          contentClass={"text-[#000]"}
        />
        <Card
          color="ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white"
          image="/assets/images/home/webLogo.png"
          heading="Web development"
          text="Websites play a key role in implying your brand's online presence.
            upkeeping with changing client's requirements, our outstanding team
            of expert web programmers delivers an impressive and secure web
            experience. we build compelling websites representing the best
            relationship with clients and engage at a high rate."
          headingClass={"text-[#000]"}
          contentClass={"text-[#000]"}
        />
      </OwlCarousel> */}
      <OwlCarousel
        data-aos="fade-up"
        data-aos-duration="1500"
        className="slider-items owl-theme map-slider owl-carousel owl-loaded owl-drag "
        stageClass="owl-stage h-[400px] flex items-center"
        loop
        {...options}
      >
        <Card
          image="/assets/images/home/UIUX.svg"
          heading="UI/UX Design"
          text="UI/UX design is crucial for any app. We deliver user-friendly designs that provide an exceptional experience and set your brand apart. Our skilled designers understand client needs and deliver excellent designs for business growth."
          color="ring-[#116EB4] bg-[#F6FBFF]"
          headingClass={"text-[#1377C3]"}
          contentClass={"text-[#000]"}
        />
        <Card
          image="/assets/images/home/QASERVICES.svg"
          heading="QA services (quality assurance)"
          text="Our quality assurance ensures a collaborative approach, covering functionality, design, usability, and compatibility testing to meet client expectations. This process ensures thorough testing and quality control before launch."
          color="ring-[#2B747E] bg-[#EFFDFF]"
          headingClass={"text-[#2B747E]"}
          contentClass={"text-[#000]"}
        />
        <Card
          image="/assets/images/home/DEVOPS.svg"
          heading="DevOps"
          text="DevOps combines cultural philosophies, practices, and tools to increase an organization's ability to deliver applications and services quickly, evolving and improving products faster than traditional software development and infrastructure management."
          color="ring-[#C24278] bg-[#fff6fa]"
          headingClass={"text-[#C24278]"}
          contentClass={"text-[#000]"}
        />
        <Card
          image="/assets/images/home/MOBILEAPP.svg"
          heading="Mobile app development"
          text="We transform business ideas into creative mobile app solutions, developing iOS, Android, and progressive web apps. Our apps feature high-quality interfaces and enhanced security, tailored to your needs. We excel in customizing every aspect, from design to functionality."
          color="ring-[#9837FF] bg-[#FCF8FF]"
          headingClass={"text-[#9837FF]"}
          contentClass={"text-[#000]"}
        />
        <Card
          image="/assets/images/home/WEBDEVELOPMENT.svg"
          heading="Web development"
          text="Websites are crucial for your brand's online presence. Our expert web programmers deliver impressive and secure web experiences, adapting to changing client needs. We create compelling websites that foster strong client relationships and high engagement."
          color="ring-[#1B6B37] bg-[#f6feff]"
          headingClass={"text-[#1B6B37]"}
          contentClass={"text-[#000]"}
        />
      </OwlCarousel>
    </div>
  );
};

export default HomeSection2;
