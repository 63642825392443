import React from "react";
import Hire from "../../components/QuoteForm/Quote";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import { useState } from "react";
import SEO from "../../components/helper/SEO/SEO";

const RequestForQuote = () => {
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);

  return (
    <>
      <SEO
        title="Request a Quote - SmartTechnica"
        description="Request a custom quote for your mobile app, web development, or e-commerce project. Get a tailored solution for your business needs with SmartTechnica."
        keywords="request a quote, custom development quote, mobile app quote, web development quote, e-commerce solutions, technology solutions quote, custom software quote, SmartTechnica RFQ"
        image="/assets/images/og/og_quote.png"
        url="https://smarttechnica.com/request-for-quote"
      />
      <div className="mt-10 lg:mt-20">
        <SucessSnackBar
          open={sucessSnackBarOpen}
          setOpen={setSucessSnackBarOpen}
          text="Thank you for contacting us !"
          type="success"
        />
        <ErrorSnackBar
          open={warningSnackBarOpen}
          text="Something Missing !"
          type="error"
        />

        <Hire
          sucessSnackBarOpen={sucessSnackBarOpen}
          setSucessSnackBarOpen={setSucessSnackBarOpen}
          warningSnackBarOpen={warningSnackBarOpen}
          setWarningSnackBarOpen={setWarningSnackBarOpen}
        />
      </div>
    </>
  );
};

export default RequestForQuote;
