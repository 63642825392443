import * as yup from "yup";

export default yup.object().shape({
  name: yup
    .string()
    .required("Name is required !")
    .matches(/^[a-zA-Z]+$/, "Name should contain only letters"),
  email: yup.string().email().required("Email is required !"),
  phone: yup
    .string()
    .required("Phone number is required"),
    // .min(10, "Phone number must be at least 10 digits")
    // .max(15, "Phone number must not exceed 15 digits"),
  message: yup.string().required("Message is required !"),
});
