import AboutUs from "../pages/AboutUs/AboutUs";
import Career from "../pages/Career/Career";
import Contact from "../pages/Contact/Contact";
import HireDeveloper from "../pages/HireDeveloper/HireDeveloper";
import Home from "../pages/Home/Home";
import Portfolio from "../pages/Portfolio/Portfolio";
import RequestForQuote from "../pages/Request-for-quote/RequestForQuote";
import Service from "../pages/ServiceNew/Service";
import AppDevelopment from "../pages/ServicesPages/AppDevelopment";
import Devops from "../pages/ServicesPages/Devops";
import FlutterDeveloper from "../pages/ServicesPages/FlutterDeveloper";
import HTMLDeveloper from "../pages/ServicesPages/HTMLDeveloper";
import ReactNativeDeveloper from "../pages/ServicesPages/ReactNativeDeveloper";
import UiuxDesign from "../pages/ServicesPages/UiuxDesign";
import WebDesign from "../pages/ServicesPages/WebDesign";
import WebDevelopment from "../pages/ServicesPages/WebDevelopment";
import GraphicDesign from "../pages/ServicesPages/GraphicDesign";
import NodeDeveloper from "../pages/ServicesPages/NodeDeveloper";
import ReactjsDevelopment from "../pages/ServicesPages/ReactjsDevelopment";
import SiteMap from "../pages/SiteMap/SiteMap";
// import WebDesign from './../pages/ServicesPages/WebDesign';

export default [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/services",
    element: <Service />,
  },
  {
    path: "/services/web-development",
    element: <WebDevelopment />,
  },
  {
    path: "/services/uiux-design",
    element: <UiuxDesign />,
  },
  {
    path: "/services/app-development",
    element: <AppDevelopment />,
  },
  {
    path: "/service/web-design",
    element: <WebDesign />,
  },
  {
    path: "/services/flutter-development",
    element: <FlutterDeveloper />,
  },
  {
    path: "/services/graphic-design",
    element: <GraphicDesign />,
  },
  {
    path: "/services/html-development",
    element: <HTMLDeveloper />,
  },
  {
    path: "/services/node-development",
    element: <NodeDeveloper />,
  },
  {
    path: "/services/react-native-development",
    element: <ReactNativeDeveloper />,
  },
  {
    path: "/services/react-js-development",
    element: <ReactjsDevelopment />,
  },

  {
    path: "/services/devops",
    element: <Devops />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/career",
    element: <Career />,
  },
  {
    path: "/contact-us",
    element: <Contact />,
  },
  {
    path: "/request-for-quote",
    element: <RequestForQuote />,
  },
  {
    path: "/hiring-developer/:id",
    element: <HireDeveloper />,
  },
  {
    path: "/site-map",
    element: <SiteMap />,
  },
];
