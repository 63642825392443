// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClaX9PKtBYxImkAjjM5MblOhdU_qoUAZY",
  authDomain: "smart-technica-71af6.firebaseapp.com",
  projectId: "smart-technica-71af6",
  storageBucket: "smart-technica-71af6.firebasestorage.app",
  messagingSenderId: "224199831486",
  appId: "1:224199831486:web:6104491708fc2221cc93ef",
  measurementId: "G-7WZ11D16BY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
