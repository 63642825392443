import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  keywords,
  image = "/assets/images/og/og_home.png",
  imageWidth = 1200, // Default width
  imageHeight = 630, // Default height
  url = "https://smarttechnica.com/",
  lang = "en",
  author = "SmartTechnica Team",
  publishedTime = "2025-01-01T10:00:00Z",
  modifiedTime = "2025-01-01T10:00:00Z",
  siteName = "SmartTechnica",
  favicon = "/favicon.ico",
}) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: title,
    url: url,
    description: description,
    image: {
      "@type": "ImageObject",
      url: image,
      width: imageWidth,
      height: imageHeight,
    },
    publisher: {
      "@type": "Organization",
      name: siteName,
      logo: {
        "@type": "ImageObject",
        url: image,
      },
    },
  };


  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <html lang={lang} />
      <link rel="icon" href={favicon} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />

      {/* Canonical URL */}
      <link rel="canonical" href={window.location.href} />

      {/* Open Graph (OG) Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={'https://smarttechnica.com'+image} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      {publishedTime && (
        <meta property="article:published_time" content={publishedTime} />
      )}
      {modifiedTime && (
        <meta property="article:modified_time" content={modifiedTime} />
      )}

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={'https://smarttechnica.com'+image} />
      <meta name="twitter:image:width" content={imageWidth} />
      <meta name="twitter:image:height" content={imageHeight} />
      <meta name="twitter:site" content={`@${siteName}`} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>

      {/* Additional Meta Tags for SEO Optimization */}
      <meta name="theme-color" content="#ffffff" />
      <meta name="apple-mobile-web-app-title" content={siteName} />
      <meta name="application-name" content={siteName} />
      <meta name="format-detection" content="telephone=no" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />

      {/* Facebook App Link (Optional) */}
      <meta property="fb:app_id" content="951000709730789" />

      {/* Mobile-specific Meta Tags */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
    </Helmet>
  );
};

export default SEO;
