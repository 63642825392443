import React from "react";
import Heading from "../../components/helper/Heading";
import RouteAnimation from "../../components/helper/RouteAnimation";
import { Link } from "react-router-dom";
import SEO from "../../components/helper/SEO/SEO";

const Service = () => {
  return (
    <>
      <SEO
        title="Services - SmartTechnica"
        description="SmartTechnica offers comprehensive services in Mobile App Development, Web Development, UI/UX Design, QA, and DevOps to help businesses thrive with innovative solutions and cutting-edge technology."
        keywords="Mobile App Development, Web Development, UI/UX Design, Quality Assurance, DevOps, React Native App Development, Android App Development, iOS App Development, Full-stack Development, E-commerce Development, Progressive Web Apps, Custom Web Development, QA Testing, Usability Testing, Automation Testing, DevOps Practices, Cloud Services, IoT, Industrial Automation"
        image="/assets/images/og/service-og-image.png"
        url="https://smarttechnica.com/services"
      />

      <RouteAnimation>
        <div className="relative">
          <div className="relative max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
            <Heading
              styles="absolute w-full top-6 esm:top-8 text-center mx-auto"
              image="/assets/images/services/servicLogo.svg"
              text="Mobile Apps"
            />
            <div className="relative mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="mx-auto"
              >
                <img src="/assets/images/services/phone.svg" alt="phone" />
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="mx-auto mt-0 lg:mt-7 space-y-3"
              >
                <h2 className="text-[26px] font-medium text-blue">
                  Transforming business ideas and building the best solutions
                  through creative mobile app development.
                </h2>
                <p className="text-[16px] opacity-80">
                  We develop ios, android apps, and progressive web apps
                  development, that demonstrate to you, and your challenges. as
                  a result, you will secure a high-quality application with a
                  great user interface and enhanced security. we are expert in
                  making your apps customized including every aspect from design
                  to functionality.
                </p>
                <p className="text-[16px] opacity-80">
                  Mobile app development is where your effort will execute
                  should progress with the usual standards like the production
                  of a compelling user interface. our development team can
                  deliver you full-cycle project capabilities for mobile
                  software development.
                </p>
                <div className="flex justify-center">
                  <img
                    className="mr-4"
                    src="/assets/images/services/Mobile app development/android.png"
                    alt=""
                  />
                  <img
                    className="mr-4"
                    src="/assets/images/services/Mobile app development/apple.png"
                    alt=""
                  />
                  <img
                    className="mr-4"
                    src="/assets/images/services/Mobile app development/native.png"
                    alt=""
                  />
                  <img
                    src="/assets/images/services/Mobile app development/flutter.png"
                    alt=""
                  />
                </div>

                <div className="grid sm:grid-cols-12 grid-cols-1 w-full gap-2">
                  <div className="sm:col-span-5 items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">
                      React native app development
                    </p>
                  </div>
                  <div className="sm:col-span-7 items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">
                      Android native app development (Java / Kotlin)
                    </p>
                  </div>
                  <div className="sm:col-span-5 items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Flutter app development</p>
                  </div>
                  <div className="sm:col-span-7 items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">
                      iOS native app development (Swift)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="absolute left-0 bottom-[-150px]"
            src="/assets/images/services/firstArrow.png"
            alt=""
          />
        </div>

        <div className=" p-3 max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
          <Heading
            styles="absolute w-full top-8 text-center mx-auto text-[34px] text-blue"
            image="/assets/images/services/webLogo.svg"
            text="Web Development"
          />
          <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="mx-auto mt-0 lg:mt-7 space-y-3"
            >
              <h2 className="text-[26px] font-medium text-blue">
                Websites play a key role in implying your brand’s online
                presence.
              </h2>
              <p className="text-[16px] opacity-80">
                Upkeeping with changing client’s requirements, our outstanding
                team of expert web programmers delivers an impressive and secure
                web experience. we build compelling websites representing the
                best relationship with clients and engage at a high rate.
              </p>
              <div className="flex justify-center">
                <img
                  className="mr-4"
                  src="/assets/images/services/vue.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/node.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/anguler.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/css.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/database.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/woo.png"
                  alt=""
                />
                <img src="/assets/images/services/react.png" alt="" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-2">
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Custom website development</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">
                    Web application development
                  </p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">
                    Ecommerce store development
                  </p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">
                    Custom CRM/ERP software apps
                  </p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Full-stack development</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Responsive web apps</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Progressive web apps</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Cms development</p>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="mx-auto"
            >
              <img
                className=""
                src="/assets/images/services/web.svg"
                alt="phone"
              />
            </div>
          </div>
        </div>

        <div className="max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
          <Heading
            styles="absolute w-full top-8 text-center mx-auto text-[34px] text-blue"
            image="/assets/images/services/UiuxLogo.svg"
            text="UI/UX Designer"
          />

          <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="mx-auto"
            >
              <img
                className=""
                src="/assets/images/services/uiux.svg"
                alt="phone"
              />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="mx-auto mt-0 lg:mt-7 space-y-3"
            >
              <h2 className="text-[26px] font-medium text-blue">
                UI/UX Design is the crowning glory of any web or mobile app.
              </h2>
              <p className="text-[16px] mt-3 opacity-80">
                SmartTechnica we deliver the best design that is user-friendly
                which gives users a prominent experience. creative ui/ux designs
                that impel your brand ahead of competitors and speak directly to
                your brand’s value. our team of skilled creative designers
                understands the requirements of clients before designing ui and
                delivers splendid ui/ux design for strategic business growth
              </p>
              <p className="text-[16px] opacity-80">
                We help ensure that your digital presence helps reach your
                business goals.
              </p>
              <div className="flex justify-center">
                <img
                  className="mr-4"
                  src="/assets/images/services/Ai.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/Xd.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/Ps.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/Ae.png"
                  alt=""
                />
                <img src="/assets/images/services/Figma.png" alt="" />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-2">
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Wireframes Designing</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Mobile App Design</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Responsive Web Design</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">UX Analysis</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">UI Design</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
            <Heading
              styles="absolute w-full top-5 esm:top-8 text-center mx-auto text-[34px] text-blue"
              image="/assets/images/services/Qalogo.svg"
              text="Quality Assurance (QA)"
            />

            <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="mx-auto mt-0 lg:mt-7 space-y-3"
              >
                <h2 className="text-[26px] font-medium text-blue">
                  Ensuring Flawless Performance and Reliability Across All
                  Platforms
                </h2>
                <p className="text-[16px] opacity-80">
                  Our quality assurance intent to deliver a collaborative
                  approach ranging from functionality testing, design testing,
                  usability testing, and app compatibility testing that suitable
                  our client’s expectations. the process enables quality
                  controland assures the application is tested thoroughly before
                  being launched.
                </p>
                <p className="text-[16px] opacity-80">
                  Smart Technica team has mastery manual and automation QA
                  engineers who can supplement the development process, create
                  test cases, and identify bugs in code. protect your mobile
                  application and web application from the risk of failure
                  during the pre-launch stage
                </p>
                <p className="text-[16px] opacity-80">
                  We design, develop and deploy custom apps that work flawlessly
                  across all devices and platforms
                </p>
                <div className="flex justify-center">
                  <img
                    className="mr-4"
                    src="/assets/images/services/Se.png"
                    alt=""
                  />
                  <img
                    className="mr-4"
                    src="/assets/images/services/Qaimg2.png"
                    alt=""
                  />
                  <img
                    className="mr-4"
                    src="/assets/images/services/turtlr.png"
                    alt=""
                  />
                  <img
                    className="mr-4"
                    src="/assets/images/services/Qaimg3.png"
                    alt=""
                  />
                  <img src="/assets/images/services/Qaimg4.png" alt="" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-2">
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">QA Documentation</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Manual Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Automation Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Mobile App Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Performance Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Api Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Usability Testing</p>
                  </div>
                  <div className="items-center flex content-center">
                    <img
                      className="w-[10px] h-[10px]"
                      src="/assets/images/services/bullet.png"
                      alt=""
                    />
                    <p className="ml-3 text-[16px]">Security Testing</p>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="mx-auto"
              >
                <img
                  className=""
                  src="/assets/images/services/Qaimg.svg"
                  alt="phone"
                />
              </div>
            </div>
          </div>
          <img
            className="absolute right-0 top-40"
            src="/assets/images/services/secondArrow.png"
            alt=""
          />
          <img
            className="-z-[999] absolute bottom-[20%] right-[42%]"
            src="/assets/images/services/YellowCircle.svg"
            alt=""
          />
          <img
            className="-z-[999] absolute top-[60%] left-[30%]"
            src="/assets/images/services/BlueCircle.svg"
            alt=""
          />
        </div>

        <div className="max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
          <Heading
            styles="absolute w-full top-8 text-center mx-auto text-[34px] text-blue"
            image="/assets/images/services/DevopsLogo.svg"
            text="DEVOPS"
          />

          <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="mx-auto"
            >
              <img
                className=""
                src="/assets/images/services/Devopsimg.svg"
                alt="phone"
              />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="mx-auto mt-0 lg:mt-7 space-y-3"
            >
              <h2 className="text-[26px] font-medium text-blue">
                Streamlining Deployment and Operations for Seamless Integration
                and Scalability
              </h2>
              <p className="text-[16px] opacity-80">
                Devops is the combination of cultural philosophies, practices,
                and tools that increases an organization’s ability to deliver
                applications and services at high velocity evolving and
                improving products at a faster pace than organizations
                usingtraditional software development and infrastructure
                management processes. devops is a set of practices that combines
                software development and it operations. devops principles are
                fundamental to practical agile. with devops at allstages of a
                project, teams can use agile practices to reduce delivery
                timeframes, and increase security and scalability as they work
                through the development stages.
              </p>
              <p className="text-[16px] opacity-80">
                Devops can be fit in the many categories like coding, building,
                testing, packaging, releasing, configuring, monitoring, etc
              </p>

              <div className="flex justify-center">
                <img
                  className="mr-4"
                  src="/assets/images/services/DevOps/cat.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/DevOps/glaas.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/DevOps/cartoon.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/Se.png"
                  alt=""
                />
                <img
                  className="mr-4"
                  src="/assets/images/services/DevOps/devimg2.png"
                  alt=""
                />
                <img
                  className="hidden md:block mr-4"
                  src="/assets/images/services/DevOps/fish.png"
                  alt=""
                />
                <img
                  className="hidden md:block mr-4"
                  src="/assets/images/services/DevOps/devimg3.png"
                  alt=""
                />
                <img
                  className="hidden md:block mr-4"
                  src="/assets/images/services/DevOps/devimg4.png"
                  alt=""
                />
                <img
                  className="hidden md:block mr-4"
                  src="/assets/images/services/DevOps/aws.png"
                  alt=""
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-2">
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Setup</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Continous Delivery</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Monitoring</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">IOT</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Smart Healthcare</p>
                </div>
                <div className="items-center flex content-center">
                  <img
                    className="w-[10px] h-[10px]"
                    src="/assets/images/services/bullet.png"
                    alt=""
                  />
                  <p className="ml-3 text-[16px]">Industrial Automation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="mt-16 bg-[#f2f3fe] rounded-t-2xl h-[100%] max-w-[95%] md:max-w-[80%] mx-auto w-full"
        >
          <div className="grid sm:grid-cols-2 grid-cols-1 px-6 py-3 gap-3">
            <div className="flex flex-col items-center">
              <div>
                <p className="text-blue font-semibold sm:text-[34px] text-[30px] sm:text-start text-center">
                  Let’s discuss your requirements
                  <span className="sm:hidden inline text-[30px] font-semibold">
                    &nbsp;right away.
                  </span>
                </p>
                <p className="text-blue font-semibold sm:text-[34px] text-[30px] text-start sm:block hidden">
                  right away.
                </p>
                <div className="flex sm:justify-start justify-center">
                  <Link
                    className="CustomButton CustomButton-border mt-5 bg-blue text-white px-5 py-3 rounded-lg"
                    to="/contact-us"
                  >
                    Contact now
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <img src="/assets/images/services/lastimg.png" alt="" />
            </div>
          </div>
        </div>
      </RouteAnimation>
    </>
  );
};

export default Service;
