import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Heading from "../../components/helper/Heading";
import api from "../../helper/api";
import { JOBS } from "../../helper/endpoints";
import Form from "../../components/HireDevelopers/Form";
import SEO from "../../components/helper/SEO/SEO";

const HireDeveloper = () => {
  const [hireDeveloper, setHireDeveloper] = useState([]);
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(true);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_API + JOBS}`)
      .then(({ data }) =>
        setHireDeveloper(data.data.filter((res) => res.slug === id && res)[0])
      )
      .catch((err) => console.log(err));
  }, [id]);
  return (
    <>

      <SEO
        title={hireDeveloper.job_title || "Hire a Developer - SmartTechnica"}
        description={
          hireDeveloper.long_description ||
          "Hire expert developers for your projects with SmartTechnica. We provide experienced professionals to bring your ideas to life."
        }
        keywords={`Hire Developers, ${hireDeveloper.job_title || ""}, ${
          hireDeveloper.skills || ""
        }, Flutter Development, Graphic Design, HTML Development, Node Development, React Native Development, React JS Development, Web Development, App Development`}
        url={window.location.href}
      />

      <section>
        <div className="relative">
          <div className="relative max-w-[95%] md:max-w-[80%] mx-auto mt-10 w-full">
            <Heading
              styles="absolute w-full top-6 esm:top-12 text-center mx-auto"
              image="/assets/images/Hiredeveloper/Developer.svg"
              text={hireDeveloper.job_title}
            />
            <div className="relative mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="mx-auto"
              >
                <img
                  src="/assets/images/Hiredeveloper/leptop.png"
                  alt="leptop"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="mx-auto mt-0 lg:mt-7"
              >
                <h2 className="text-[26px] text-blue font-semibold">
                  {hireDeveloper.job_title}
                </h2>
                {hireDeveloper.can_fresher_apply && (
                  <p className={` leading-none text-[16px] text-blue mt-2`}>
                    Fresher with good knowledge can also apply
                  </p>
                )}
                <p className="text-[16px] mt-3">
                  {hireDeveloper.long_description}
                </p>
                <div className="flex justify-center mt-3 p-3  w-[150px] h-[130px] overflow-hidden">
                  <img
                    className="mx-auto my-auto h-full"
                    src={`${
                      process.env.REACT_APP_UPLOADS + hireDeveloper.job_image
                    }`}
                    alt=""
                  />
                </div>
                <div className="flex flex-wrap">
                  {hireDeveloper.length !== 0 &&
                    hireDeveloper.description.map((res) => (
                      <>
                        <div
                          key={res}
                          className="mt-3 w-1/2  flex content-center items-center"
                        >
                          <img
                            className="w-[10px] h-[10px]"
                            src="/assets/images/services/bullet.png"
                            alt="dots"
                          />
                          <p className="ml-3">{res}</p>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <img
            className="absolute left-0 bottom-[-150px]"
            src="/assets/images/services/firstArrow.png"
            alt=""
          />
        </div>
        <Form
          jobtitle={hireDeveloper.job_title}
          sucessSnackBarOpen={sucessSnackBarOpen}
          setSucessSnackBarOpen={setSucessSnackBarOpen}
          warningSnackBarOpen={warningSnackBarOpen}
          setWarningSnackBarOpen={setWarningSnackBarOpen}
        />
        {/* <ApplyNowModel/> */}
      </section>
    </>
  );
};

export default HireDeveloper;
