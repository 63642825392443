import RouteAnimation from "../../components/helper/RouteAnimation";
import HomeMain from "../../components/HomeMain/HomeMain";
import HomeSection2 from "../../components/HomePage/HomeSection2";
import WhySmartTechnica from "../../components/HomePage/WhySmartTechnica";
import Review from "../../components/HomePage/Review";
import OneRoof from "../../components/HomePage/OneRoof";
import Offer from "../../components/HomePage/Offer";
import Skills from "../../components/HomePage/Skills";
import SEO from "../../components/helper/SEO/SEO";

const Home = () => {
  return (
    <>
      <SEO
        title="Home - SmartTechnica"
        description="SmartTechnica specializes in mobile apps, game development, and web solutions, turning ideas into reality with a creative, results-driven approach to business success."
        keywords="Mobile App Development, Game Development, Web Design, IT Solutions, Custom Software, Business Solutions, Digital Transformation, Web Development, Cloud Services, IT Consulting, Technology Innovation"
      />

      <RouteAnimation>
        <HomeMain />
        <HomeSection2 />
        <WhySmartTechnica />
        <Review />
        <OneRoof />
        <Offer />
        <Skills />
      </RouteAnimation>
    </>
  );
};

export default Home;
