import React from "react";

const Card = (props) => {
  const { color, image, heading, text, headingClass, contentClass } = props;

  return (
    <div
      className={`${color} ring-1  mx-auto relative w-[250px] fold:w-[350px] h-auto fold:h-[350px] rounded-2xl shadow-xl bg-white transition-all  p-4 space-y-2`}
    >
      <div className="max-h-[80px] max-w-[80px] mx-auto">
        <img className="h-full w-full" src={image} alt="icons" />
      </div>
      <h3
        className={`font-medium mb-3 text-[18px] text-center ${headingClass}`}
      >
        {heading}
      </h3>
      <p className={`text-xs text-center tracking-[0.5px] max-h-[175px] overflow-hidden ${contentClass}`}>{text}</p>
    </div>
  );
};

export default Card;
