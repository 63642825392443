import React from "react";

const OneRoof = () => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-duration="1500"
      className="bg-gradient-to-tr from-[#fef9f8] via-[#f8f8fb] to-[#f7f4e6]"
    >
      <div className="mt-20 max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
        <div className="relative">
          <img className="mx-auto" src="/assets/images/home/smart.svg" alt="" />
          <div className="absolute w-full top-5 esm:top-12 text-center mx-autoleading-6 esm:leading-8 text-[26px] esm:text-[34px] text-blue pt-4">
            With &nbsp;
            <span className="leading-6 esm:leading-8 text-[26px] esm:text-[34px] text-[#09538b]">
              Smart Technica
            </span>
            , you will have everything
            <p className="leading-6 esm:leading-8 text-[26px] esm:text-[34px] text-blue">
              you need under one roof.
            </p>
          </div>
        </div>
        <div className="mt-[190px] md:mt-20 flex flex-wrap justify-center">
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto"
                src="/assets/images/home/planning.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Planning</h4>
              <p className="opacity-80 text-[18px]">
                We research to understand the vision. The audience.The product.
                The goals. We collaborate, we re- examine, we ask and conclude
              </p>
            </div>
          </div>
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto"
                src="/assets/images/home/design.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Design</h4>
              <p className="opacity-80 text-[18px]">
                Working passionately with you, our designers create
                aesthetically pleasing and user friendly digital experiences
              </p>
            </div>
          </div>
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto"
                src="/assets/images/home/development.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Development</h4>
              <p className="opacity-80 text-[18px]">
                Backend and frontend. from the beginning, we have performance
                and maintainability in mind. always insisting on best practices
                and keeping up with the latest trends.
              </p>
            </div>
          </div>
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto h-[200px] w-[200px]"
                src="/assets/images/home/Untitled-1.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Testing</h4>
              <p className="opacity-80 text-[18px]">
                Just by running functional tests on a mobile application, you
                cannot sign off the app. There are few other testing types like
                field testing, network testing, UI testing, battery life
                testing, etc. that need to be done.
              </p>
            </div>
          </div>
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto"
                src="/assets/images/home/marketing.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Marketing</h4>
              <p className="opacity-80 text-[18px]">
                From organic growth to writing irresistible ads for your
                marketing campaign, we're here to help you. We'll help you build
                a brand and community of prospects who can give you a boost on
                your product launch.
              </p>
            </div>
          </div>
          <div className="md:basis-1/2 lg:basis-1/3">
            <div className="m-3">
              <img
                className="mx-auto"
                src="/assets/images/home/support.gif"
                alt=""
              />
              <h4 className="text-blue text-[22px] mb-3">Support</h4>
              <p className="opacity-80 text-[18px]">
                From bug fixing to feature updates, you can count on us. For the
                last 7 years, our clients had a 99.87% uptime for their
                products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneRoof;
