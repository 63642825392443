import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false,
    ads: false,
  });

  useEffect(() => {
    const userConsent = Cookies.get("userConsent");
    const savedPreferences = Cookies.get("cookiePreferences");

    if (userConsent || savedPreferences) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("userConsent", "accepted", { expires: 150 });
    Cookies.set("cookiePreferences", JSON.stringify(preferences), {
      expires: 150,
    });
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    Cookies.set("cookiePreferences", JSON.stringify(preferences), {
      expires: 150,
    });
    setShowPreferences(false);
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <section className="fixed max-w-md p-3 w-[95%] bg-blue border border-gray-600 sm:mx-0 left-1/2 -translate-x-1/2 sm:translate-x-0 sm:left-3 bottom-3 shadow-lg rounded-xl z-[999999]">
          <h2 className="font-semibold text-white">🍪 Cookie Notice</h2>
          <p className="mt-3 text-xs text-white">
            We use cookies to ensure that we give you the best experience on our
            website.
          </p>
          <div className="flex items-center justify-between mt-3 gap-x-3 shrink-0">
            <button
              onClick={() => setShowPreferences(true)}
              className="text-xs text-gray-200 underline transition-colors duration-300 hover:text-gray-400 focus:outline-none"
            >
              Manage your preferences
            </button>

            <button
              onClick={handleAccept}
              className="text-[12px] bg-gray-900 font-medium rounded-[4px] hover:bg-gray-800 text-white px-3 py-2 duration-300 transition-colors focus:outline-none"
            >
              Accept
            </button>
          </div>
        </section>
      )}

      {/* Preferences Modal */}
      {showPreferences && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999999]">
          <div className="bg-blue p-6 min-w-[450px] rounded-lg shadow-lg max-w-lg">
            <h2 className="font-semibold text-white">Manage Cookie Preferences</h2>
            <p className="text-sm text-gray-200">Select which cookies you want to allow.</p>

            <div className="mt-4">
              <label className="flex items-center space-x-2">
                <input type="checkbox" checked={preferences.essential} disabled className="cursor-not-allowed accent-black" />
                <span className="text-sm text-gray-200">Essential Cookies (Required)</span>
              </label>
              <label className="flex items-center space-x-2 mt-2">
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={() =>
                    setPreferences((prev) => ({
                      ...prev,
                      analytics: !prev.analytics,
                    }))
                  }
                  className="accent-black"
                />
                <span className="text-sm text-white">Analytics Cookies</span>
              </label>
              <label className="flex items-center space-x-2 mt-2">
                <input
                  type="checkbox"
                  checked={preferences.ads}
                  onChange={() =>
                    setPreferences((prev) => ({ ...prev, ads: !prev.ads }))
                  }
                  className="accent-black"
                />
                <span className="text-sm text-white">Advertisement Cookies</span>
              </label>
            </div>

            <div className="flex justify-end gap-x-2 mt-4">
              <button
                onClick={() => setShowPreferences(false)}
                className="text-xs text-gray-200 hover:text-gray-300 focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={handleSavePreferences}
                className="text-xs bg-blue-500 font-medium rounded-lg hover:bg-blue-600 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
