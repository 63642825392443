import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Heading from "../../components/helper/Heading";
import RouteAnimation from "../../components/helper/RouteAnimation";
import Card from "../../components/helper/JobOpeningCard/Card";
import ContactUs from "../../components/ContactUs/ContactUs";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import api from "../../helper/api";
import { PRODUCT, SMARTIMAGES } from "../../helper/endpoints";
import CarrerPoints from "./CarrerPoints";
import SEO from "../../components/helper/SEO/SEO";

const Career = () => {
  const options = {
    margin: 30,
    autoplay: true,
    smartSpeed: 20000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1350: {
        items: 3,
      },
      1815: {
        items: 4,
      },
    },
    dots: false,
    nav: false,
  };

  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [productData, setProductData] = useState();

  const getContact = async () => {
    api
      .get(`${process.env.REACT_APP_API + SMARTIMAGES}`)
      .then((res) => {
        setProductData(
          res.data.data.reduce((acc, { _id, smartimages }) => {
            smartimages.forEach((image) => {
              acc.push({ _id, images: image });
            });
            return acc;
          }, [])
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <>
      <SEO
        title="Careers - SmartTechnica"
        description="Join SmartTechnica for an exciting career with cutting-edge technology, continuous learning, and work-life balance. Explore job openings and grow with us!"
        keywords="careers at SmartTechnica, job opportunities, IT jobs, web development careers, mobile app development jobs, game development careers, software engineering jobs, technology company careers, work-life balance, employee benefits, career growth, professional development, leadership development, flexible leave policies, collaborative culture"
        image="/assets/images/og/career-og-image.png"
        url="https://smarttechnica.com/career"
      />

      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Thank you for contacting us !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <RouteAnimation>
        <div className="relative overflow-hidden">
          <img
            src="/assets/images/servicesNew/firstArrow.png"
            className="absolute top-96"
            alt=""
          />
          <div className="mt-10 max-w-[95%] md:max-w-[85%] mx-auto">
            <Heading
              styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
              image="/assets/images/Career/Benefit.svg"
              text="Why you’ll love it at Smart Technica"
            />
            <div className="grid gap-5 mt-10 sm:grid-cols-1 md:grid-cols-2">
              <div
                className="m-auto sm:max-w-[75%] max-w-full"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <img
                  className=""
                  src="/assets/images/Career/benifitImg.svg"
                  alt=""
                />
              </div>
              <div
                className="m-auto text-black grid grid-cols-2 gap-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <CarrerPoints text={"Cutting-Edge Technology"} />
                <CarrerPoints text={"Collaborative Culture"} />
                <CarrerPoints text={"Work-Life Balance"} />
                <CarrerPoints text={"Continuous Learning Programs"} />
                <CarrerPoints text={"Open Communication Channels  "} />
                <CarrerPoints text={"Knowledge Sharing Sessions"} />
                <CarrerPoints text={"Innovation and Creativit"} />
                <CarrerPoints text={"Leadership Development Programs"} />
                <CarrerPoints
                  text={"Training & learning from the professionals"}
                />
                <CarrerPoints text={"Internal Competitions"} />
                <CarrerPoints text={"Alternative Weekends Off"} />
                <CarrerPoints text={"12 Paid leaves"} />
                <CarrerPoints text={"Cover Festival Holiday leaves "} />
                <CarrerPoints text={"Office Games and Activities"} />
                <CarrerPoints text={"Tech Talks"} />
                <CarrerPoints text={"R&D Opportunities"} />
                <CarrerPoints text={"Regular Feedback and Reviews"} />
                <CarrerPoints text={"Community Engagement"} />
                <CarrerPoints text={"Employee Recognition"} />
                <CarrerPoints text={"Flexible Leave Policies"} />
              </div>
            </div>
            <img
              className="-z-[999] absolute bottom-[0%] left-[40%]"
              src="/assets/images/servicesNew/BlueCircle.svg"
              alt=""
            />
            <img
              className="-z-[999] absolute top-[65%] right-[40%]"
              src="/assets/images/servicesNew/YellowCircle.svg"
              alt=""
            />
            <Heading
              styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
              image="/assets/images/Career/Life.svg"
              text="Life @ Smart Technica"
            />
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="bg-[#edf5fe] text-center mt-3 md:mt-6 rounded-2xl"
            >
              <p className="p-5 text-[18px]">
                we strive to create an environment where our employees feel
                valued, inspired, and empowered to make a difference. We believe
                that a happy and engaged workforce leads to greater
                productivity, innovation, and overall success. Join us and
                experience a fulfilling and rewarding career where you can
                thrive personally and professionally. Smart Technica is
                everything more than a job.
              </p>
            </div>
            {/* <div
              className="mt-10 esm:mt-3 bg-[#f2f3fe] max-w-[1440px] mx-auto text-center"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <p className="px-12 py-6 text-[18px] esm:text-[21px]">
                we strive to create an environment where our employees feel
                valued, inspired, and empowered to make a difference. We believe
                that a happy and engaged workforce leads to greater
                productivity, innovation, and overall success. Join us and
                experience a fulfilling and rewarding career where you can
                thrive personally and professionally. Smart Technica is
                everything more than a job.
              </p>
            </div> */}

            <div
              className="flex justify-between overflow-auto gap-4 mt-5 mb-10"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              {productData && (
                <OwlCarousel
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="slider-items owl-theme map-slider owl-carousel owl-loaded owl-drag"
                  loop
                  {...options}
                >
                  {productData.map((object, index) => (
                    <img
                      key={index}
                      className="w-[500px] h-[300px]"
                      src={process.env.REACT_APP_UPLOADS + object.images}
                      alt="uploded-img"
                    />
                  ))}
                </OwlCarousel>
              )}
            </div>

            <Heading
              styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
              image="/assets/images/Career/Career.svg"
              text="Current job opening"
            />
            <div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-10 lg:gap-10"
              data-aos="zoom-out"
              data-aos-duration="1500"
            >
              <Card
                sucessSnackBarOpen={sucessSnackBarOpen}
                setSucessSnackBarOpen={setSucessSnackBarOpen}
                warningSnackBarOpen={warningSnackBarOpen}
                setWarningSnackBarOpen={setWarningSnackBarOpen}
              />
            </div>
          </div>
        </div>
        <div
          className="bg-[#f2f3fe] mt-20 py-6"
          data-aos="zoom-out"
          data-aos-duration="1500"
        >
          <div className=" max-w-[95%] md:max-w-[85%] mx-auto relative">
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
              <div>
                <div className="relative">
                  <img
                    className=""
                    src="/assets/images/Career/Connect.svg"
                    alt=""
                  />
                  <p className="absolute w-full top-8 esm:top-12 text-[34px] text-blue m-auto">
                    Connect with us!
                  </p>
                </div>
                <p className="my-4 text-blue text-[24px]">
                  To make requests for further information, contact us via the
                  form provided
                </p>
                <p>We are always available! you will hear from us shortly.</p>
              </div>
              <ContactUs
                sucessSnackBarOpen={sucessSnackBarOpen}
                setSucessSnackBarOpen={setSucessSnackBarOpen}
                warningSnackBarOpen={warningSnackBarOpen}
                setWarningSnackBarOpen={setWarningSnackBarOpen}
              />
            </div>
          </div>
        </div>
      </RouteAnimation>
    </>
  );
};

export default Career;
